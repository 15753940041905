import React from 'react';

/**
 * Finds any [visibleText](linkUrl) in a string and returns
 * an array of text pieces + <a> elements.
 *
 * Example:
 *   "Here is [Duck Duck Go](https://duckduckgo.com) link."
 * will render as:
 *   "Here is " + <a href="https://duckduckgo.com">Duck Duck Go</a> + " link."
 */
const parseMarkdownLinks = (text) => {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = linkRegex.exec(text)) !== null) {
        // text before this match
        if (match.index > lastIndex) {
            parts.push(text.slice(lastIndex, match.index));
        }

        // match[1] = link text inside [ ]
        // match[2] = link URL inside ( )
        parts.push(
            <a
                key={match.index}
                href={match[2]}
                className="text-yellow-500 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
            >
                {match[1]}
            </a>
        );

        lastIndex = linkRegex.lastIndex;
    }

    // push any text after the final match
    if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex));
    }

    return parts;
};

const RightPart = ({ sections }) => {
    return (
        <div className="md:w-2/3">
            {sections.map((section, i) => (
                <div key={i} className={`${section.id === 'professionalSummary' ? 'hidden md:block' : ''} mb-6 md:mb-8`}>
                    <section className="mb-6">
                        <h2 className="mb-4">{section.title}</h2>

                        {section.type === "text" && (
                            // Render text elements as paragraphs
                            section.elements.map((text, textIndex) => (
                                <p key={textIndex} className="mb-4 text-gray-800 leading-relaxed">
                                    {parseMarkdownLinks(text)}
                                </p>
                            ))
                        )}

                        {section.type !== "text" && section.elements.map((job, j) => (
                            job.type === 'pdf-empty-space' ? (
                                <div key={j} className="pdf-only-space" style={{ height: job.size || 0 }}></div>
                            ) : (
                                <article key={j} className="mb-6">
                                    {/* Gray Background Box for Job Title, Employer, and Date */}
                                    <div className="bg-gray-100 py-4 px-8 mb-4 -mx-8">
                                        <h3>{job.title}</h3>
                                        <p className="text-lg leading-tight">{job.employer}</p>
                                        <p className="font-light text-lg leading-tight">{job.dates}</p>
                                    </div>

                                    {/* Job Descriptions */}
                                    {job.responsibilities.length > 0 && (
                                        <ul className="list-disc list-outside ml-6 space-y-1">
                                            {job.responsibilities.map((responsibility, respIndex) => (
                                                <li key={respIndex}>
                                                    {parseMarkdownLinks(responsibility)}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </article>
                            )
                        ))}
                    </section>

                    {i < sections.length - 1 && (
                        <div className="block md:hidden border-t-[0.5px] border-orange-400 w-full h-px bg-white mt-4 mb-7 mr-4 -ml-8"></div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default RightPart;
