import React, {useContext} from 'react';
import iconMap from '../utils/iconMap';
import {Tools} from "../services/tools";
import {ShowAllContext} from "../contexts/ShowAllContext ";

const LeftPart = ({sections}) => {
  const {isShowAll} = useContext(ShowAllContext);

  return (
      <div className="md:w-1/3 md:text-right">
        {sections.map((section, i) => (
            <div key={i} className={section.id === 'professionalSummary' ? 'block md:hidden' : ''}>
              <section className="mb-6 md:mb-8">
                <h2>{section.title}</h2>

                {(() => {
                  switch (section.type) {
                    case 'skills':
                      return section.elements.map((element, j) => (
                          <div key={j} className="">
                            <h3 className={`mb-4 ${j !== 0 ? 'mt-6' : ''}`}>{element.title}</h3>
                            {(() => {
                              switch (element.type) {
                                case 'pdf-empty-space':
                                  return (
                                      <div
                                          className="pdf-only-space"
                                          style={{height: element.size || 0}}
                                      ></div>
                                  );
                                default:
                                  return (
                                      <>
                                        {element.items && <p>{element.items.join(', ')}</p>}
                                        {element.subcategories &&
                                        element.subcategories.map((subcategory, k) => (
                                            <div key={k} className="mb-2">
                                              {subcategory.title && <h4>{subcategory.title}</h4>}
                                              <p>{subcategory.items.join(', ')}</p>
                                            </div>
                                        ))}
                                      </>
                                  );
                              }
                            })()}
                          </div>
                      ));

                    case 'contact':
                      return (
                          <div className="flex flex-col gap-2">
                            {section.elements.map((element, l) => (
                                (element.type !== 'phone' || isShowAll) &&
                                <div key={l} className={`flex md:justify-end gap-3 ${element.class || ''}`}>
                                  <div className="md:order-last">
                                    {element.icon && iconMap[element.icon] ? (
                                        React.createElement(iconMap[element.icon], {className: "size-5 mt-0.5"})
                                    ) : (
                                        React.createElement(iconMap['unknown'], {className: "size-5 mt-0.5"})
                                    )}
                                  </div>

                                  {(() => {
                                    switch (element.type) {
                                      case 'email':
                                        return (
                                            <a className="hover:underline" href={element.url}>
                                              {element.value}
                                            </a>
                                        );

                                      case 'phone':
                                        return (
                                            <div>{Tools.decrypt(element.value)}</div>
                                        )

                                      default:
                                        return (
                                            <>
                                              {element.url ? (
                                                  <a
                                                      className="text-yellow-500 hover:underline"
                                                      href={element.url}
                                                  >
                                                    {element.value}
                                                  </a>
                                              ) : (
                                                  <div>{element.value}</div>
                                              )}
                                            </>
                                        );
                                    }
                                  })()}
                                </div>
                            ))}
                          </div>
                      );

                    case 'text':
                      return section.elements.map((text, textIndex) => (
                          <p key={textIndex} className="mb-4 text-gray-800 leading-relaxed">
                            {text}
                          </p>
                      ));

                    default:
                      return null;
                  }
                })()}

                {/* Add more conditions for other section types as needed */}
              </section>
              {i < sections.length - 1 && (
                  <div
                      className="block md:hidden border-t-[0.5px] border-orange-400 w-full h-px bg-white mt-4 mb-7 mr-4 -ml-8"></div>
              )}
            </div>
        ))}
      </div>
  );
};

export default LeftPart;
