import React, { createContext, useState, useEffect } from 'react';
import {Tools} from "../services/tools";

export const ShowAllContext = createContext();

export const ShowAllProvider = ({ children }) => {
  const [isShowAll, setIsShowAll] = useState(false);

  useEffect(() => {
    // Parse the URL's query params
    const params = new URLSearchParams(window.location.search);
    if (params.get('action') === Tools.decrypt('{pw\x7F5itt')) {
      setIsShowAll(true);
    }
  }, []);

  return (
      <ShowAllContext.Provider value={{ isShowAll }}>
        {children}
      </ShowAllContext.Provider>
  );
};
