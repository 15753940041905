import React, { useContext } from 'react';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { LoadingContext } from '../contexts/LoadingContext';
import config from '../assets/config/config.json';
import {ShowAllContext} from "../contexts/ShowAllContext ";
import {Tools} from "../services/tools";

const DownloadButton = () => {
  const setLoading = useContext(LoadingContext);
  const {isShowAll} = useContext(ShowAllContext);

  const handleDownload = async () => {
    setLoading(true);

    const downloadResumeUrl = config['download-resume-url'];

    try {
      // Make a request to your BFF to generate the PDF
      const response = await fetch(`${downloadResumeUrl}${isShowAll ? Tools.decrypt('5xpwvm') : ''}.pdf`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch the PDF');
      }

      // Get the PDF data as a blob
      const pdfBlob = await response.blob();

      // Create a URL for the PDF blob and trigger the download
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', 'resume[martin-dedik].pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);

    } catch (error) {
      console.error('Error downloading PDF:', error);
    } finally {
      // End loading state
      setLoading(false);
    }
  };

  return (
      <button
          onClick={handleDownload}
          className="relative bg-white rounded-full shadow hover:shadow-md focus:outline-none p-2 cursor-pointer md:shadow-md md:hover:shadow-lg transition-shadow duration-200 ease-in-out"
      >
        <DocumentIcon className="w-[24px] h-[24px]" />
        <span className="absolute top-2.5 left-3.5 text-[6px] font-bold">PDF</span>
      </button>
  );
};

export default DownloadButton;
