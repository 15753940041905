// src/App.jsx
import React from 'react';
import Resume from './Resume';
import {ShowAllProvider} from "../contexts/ShowAllContext ";

const App = () => {
  return (
      <ShowAllProvider>
        <Resume/>
      </ShowAllProvider>
  );
};

export default App;
