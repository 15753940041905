export class Tools {
  static encrypt(text) {
    const shift = 8;
    return text
        .split("")
        .map(char => String.fromCharCode(char.charCodeAt(0) + shift))
        .join("");
  }

  static decrypt(encryptedText) {
    const shift = 8;
    return encryptedText
        .split("")
        .map(char => String.fromCharCode(char.charCodeAt(0) - shift))
        .join("");
  }
}

document.toolsEncrypt = Tools.encrypt;
