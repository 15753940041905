import React, {useState} from 'react';
import TitlePart from './TitlePart';
import LeftPart from './LeftPart';
import RightPart from './RightPart';
import ScrollToTopButton from './ScrollToTopButton';
import resumeData from '../store/resumeData.json';
import {LoadingContext} from "../contexts/LoadingContext";

const Resume = () => {

  const [isLoading, setIsLoading] = useState(false);

  const titleData = resumeData.sections.find(section => section.location === "title");
  const leftData = resumeData.sections.filter(section => ["left", "both"].includes(section.location) );
  const rightData = resumeData.sections.filter(section => ["right", "both"].includes(section.location));

  return (

      <LoadingContext.Provider value={setIsLoading}>
        {isLoading && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="loader border-t-transparent border-solid rounded-full w-16 h-16 border-4 border-white animate-spin"></div>
            </div>
        )}
        <div className="max-w-5xl mx-auto py-8 bg-white shadow-lg" id="resume">

          {/* Header Section */}
          <TitlePart data={titleData}/>

          <div className="flex flex-col md:flex-row px-8">
            {/* Left Section: Contact and Skills */}
            <LeftPart sections={leftData}/>

            {/* Horizontal Divider */}
            <div className="block md:hidden relative z-10 border-t-[0.5px] border-orange-400 w-full h-px bg-white mt-4 mb-7 mr-4 -ml-8"></div>

            {/* Vertical Line Divider */}
            <div className="hidden md:block border-r border-orange-400 w-0 bg-white mx-8"></div>

            {/* Right Section: Work Experience */}
            <RightPart sections={rightData}/>
          </div>

          {/* Scroll-to-top button */}
          <ScrollToTopButton className="md:hidden"/>

        </div>
      </LoadingContext.Provider>
  );

};

export default Resume;
